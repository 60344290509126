'use strict';

function formViolateUp(scroll = true) {
  const $form = jQuery('.main-form');
  const $masthead = jQuery('.masthead');
  if (window.innerWidth < 768 || !(jQuery('.form-section.expanded').length > 0)) {
    $form.removeAttr('style');
    return;
  }
  if (!($form.css('margin-top') < 0)){
    let t = $masthead.height() - 36;
    $form.css({'margin-top': (0 - t)+'px'});
    if (scroll) {
      scrollToElement(jQuery('body'));
    }
  }
};

function scrollToElement($element, offset) {
  var top = $element.offset().top;
  var m = parseInt($element.css('margin-top'));
  top += m;
  jQuery('html, body').animate({
    scrollTop: top
  }, 200);
}

function SectionCollapse($section, type) {
  const $content = $section.find('.section-content');
  $content.collapse(type);
  if (type == 'toggle') {
    var expanded = $section.attr('aria-expanded') == 'true';
    $section.attr('aria-expanded', !expanded);
    $section.toggleClass('expanded');
  } else if (type == 'show'){
    $section.attr('aria-expanded', true);
    if(!$section.hasClass('expanded')){
      $section.addClass('expanded');
    }
  }
  formViolateUp(false);
  if($section.hasClass('expanded')){
    setTimeout(function(){
      scrollToElement($section, -200);
    }, 200);
  }
}

function checkFormComplete($el) {
  var complete = true;
  var tested = [];
  $el.find('input').each(function(){
    var $input = jQuery(this);
    var name = $input.attr('name');
    if (tested.indexOf(name) > -1){
      return;
    } else {
      tested.push(name);
    }
    var val = null;
    switch ($input.attr('type')) {
      case 'radio':
      case 'checkbox':
        val = getGroupVal($input);
        break;
      case 'submit':
      case 'hidden':
        val = true;
        break;
      default:
        val = $input.val();
        break;
    }
    if (!val && $input.attr('data-required') === 'required') {
      complete = false;
    }
  });
  return complete;
}

function getGroupVal($input) {
  var name = $input.attr('name');
  var $selected = jQuery('input[name="'+name+'"]:checked');
  if ($selected) {
    return $selected.val();
  } else {
    return null;
  }
};

// Parse through querystring and update form values
function getPrePopValues($form){
  var href = window.location.href;
  var values = {};
  if (href.indexOf('?') > -1) {
    var queryString = href.split('?')[1];
    var params = queryString.split('&');
    
    for (var i=0;i<params.length;i++){
      var param = decodeURIComponent(params[i]);
      var key = param.split('=')[0];
      var val = param.split('=')[1];
      values[key] = val;
      var $field = jQuery('input[name="'+key+'"]');
      switch ($field.attr('type')) {
        case 'radio':
        case 'checkbox':
          $field = jQuery('input[name="'+key+'"][value="'+val+'"]');
          $field.prop('checked', true);
          break;
        default:
          $field.val(val);
          break;
      }
    }
  }
}

jQuery( document ).ready(function() {
  
  // Set footer year
  const date = new Date();
  const year = date.getFullYear();
  jQuery('.copyright .year').text(year);
  // Open the default form section
  jQuery('.form-section.expanded').collapse('show');
  // Section header collapse toggle onclick
  jQuery('.section-header').click(function(){
    const $section = jQuery(this).closest('.form-section');
    SectionCollapse($section, 'toggle');
  });
  // Pre-populate form values
  var $form = jQuery('.main-form');
  getPrePopValues($form);
  // Move the form violation based on screen size
  formViolateUp(true);
  let resizeBounce = null;
  jQuery( window ).resize(function() {
    if (resizeBounce) {
      clearTimeout(resizeBounce);
    }
    resizeBounce = setTimeout(function(){
      formViolateUp();
    }, 100);
  });
});

// Watch for form changes:
// open sections as they are completed.
// Enable / disable submit based on completeness

jQuery('.main-form input').on('change', function(){
  const $submit = jQuery('.main-form input[type="submit"]');
  const $field = jQuery(this);
  const $section = $field.closest('.form-section');

  // If a section is complete, open up the next one
  var sectionComplete = checkFormComplete($section);
  
  const $next = $section.next();
  if (sectionComplete && $next.hasClass('form-section')) {
    SectionCollapse($next,'show');
  }

  // toggle the submit based on form completion
  var $form = jQuery('.main-form');
  if (checkFormComplete($form)){
    $submit.removeAttr('disabled');
  } else {
    $submit.attr('disabled','disabled');
  }
});
