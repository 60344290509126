function handleFormSubmit(ele) {
    var submitButton = ele.querySelector('input[type=submit]');
    var spinner = document.createElement('span');
    spinner.setAttribute('class', 'loader');
    submitButton.setAttribute('disabled', true);
    submitButton.style.cursor = 'wait';
    submitButton.parentNode.appendChild(spinner);
    return true;
  }
  function resetSubmitButton(e){
    var submitButtons = e.target.form.getElementsByClassName('submit-button');
    for(var i=0;i<submitButtons.length;i++){
      submitButtons[i].disabled = false;
    }
  }
  function addChangeHandler(elements){
    for(var i=0; i<elements.length; i++){
      elements[i].addEventListener('change', resetSubmitButton);
    }
  }
  var form = document.getElementById('form115');
  addChangeHandler(form.getElementsByTagName('input'));
  addChangeHandler(form.getElementsByTagName('select'));
  addChangeHandler(form.getElementsByTagName('textarea'));
  var nodes = document.querySelectorAll('#form115 input[data-subscription]');
  if (nodes) {
    for (var i = 0, len = nodes.length; i < len; i++) {
      var status = nodes[i].dataset ? nodes[i].dataset.subscription : nodes[i].getAttribute('data-subscription');
      if(status ==='true') {
        nodes[i].checked = true;
      }
    }
  };
  var nodes = document.querySelectorAll('#form115 select[data-value]');
  if (nodes) {
    for (var i = 0; i < nodes.length; i++) {
      var node = nodes[i];
      var selectedValue = node.dataset ? node.dataset.value : node.getAttribute('data-value');
      if (selectedValue) {
        for (var j = 0; j < node.options.length; j++) {
          if(node.options[j].value === selectedValue) {
            node.options[j].selected = 'selected';
            break;
          }
        }
      }
    }
  }
  console.log(this);
  var getParentElement = function(list) {
    return list[list.length-1].parentElement};
  var dom0 = document.querySelectorAll('#form115 [name="radioButtons"]');
  var fe1216 = new LiveValidation(dom0, {
    validMessage: "", onlyOnBlur: false, wait: 300, insertAfterWhatNode :getParentElement(dom0), isGroup: true}
                                  , true);
  fe1216.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1216.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1216.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom1 = document.querySelectorAll('#form115 [name="radioButtons2"]');
  var fe1217 = new LiveValidation(dom1, {
    validMessage: "", onlyOnBlur: false, wait: 300, insertAfterWhatNode :getParentElement(dom1), isGroup: true}
                                  , true);
  fe1217.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1217.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1217.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom2 = document.querySelectorAll('#form115 [name="radioButtons3"]');
  var fe1218 = new LiveValidation(dom2, {
    validMessage: "", onlyOnBlur: false, wait: 300, insertAfterWhatNode :getParentElement(dom2), isGroup: true}
                                  , true);
  fe1218.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1218.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1218.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom3 = document.querySelectorAll('#form115 [name="radioButtons4"]');
  var fe1219 = new LiveValidation(dom3, {
    validMessage: "", onlyOnBlur: false, wait: 300, insertAfterWhatNode :getParentElement(dom3), isGroup: true}
                                  , true);
  fe1219.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1219.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  var dom4 = document.querySelectorAll('#form115 [name="radioButtons5"]');
  var fe1220 = new LiveValidation(dom4, {
    validMessage: "", onlyOnBlur: false, wait: 300, insertAfterWhatNode :getParentElement(dom4), isGroup: true}
                                  , true);
  fe1220.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1220.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1220.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom5 = document.querySelector('#form115 #fe1221');
  var fe1221 = new LiveValidation(dom5, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1221.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1221.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1221.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
            );
  fe1221.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom6 = document.querySelector('#form115 #fe1222');
  var fe1222 = new LiveValidation(dom6, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1222.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1222.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1222.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
            );
  var dom7 = document.querySelector('#form115 #fe1223');
  var fe1223 = new LiveValidation(dom7, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1223.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1223.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1223.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
            );
  fe1223.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom8 = document.querySelector('#form115 #fe1224');
  var fe1224 = new LiveValidation(dom8, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1224.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1224.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1224.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
            );
  fe1224.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom9 = document.querySelector('#form115 #fe1225');
  var fe1225 = new LiveValidation(dom9, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1225.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1225.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1225.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom10 = document.querySelector('#form115 #fe1226');
  var fe1226 = new LiveValidation(dom10, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1226.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1226.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1226.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
            );
  fe1226.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom11 = document.querySelector('#form115 #fe1227');
  var fe1227 = new LiveValidation(dom11, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1227.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1227.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1227.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
            );
  fe1227.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom12 = document.querySelector('#form115 #fe1228');
  var fe1228 = new LiveValidation(dom12, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1228.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URL's"}
            );
  fe1228.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1228.add(Validate.Length, {
    tooShortMessage:"Invalid length for field value", tooLongMessage: "Invalid length for field value",  minimum: 0, maximum: 35}
            );
  fe1228.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  var dom13 = document.querySelector('#form115 #fe1229');
  var fe1229 = new LiveValidation(dom13, {
    validMessage: "", onlyOnBlur: false, wait: 300}
                                 );
  fe1229.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
  fe1229.add(Validate.Format, {
    pattern: /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i, failureMessage: "A valid email address is required"}
            );
  var dom14 = document.querySelectorAll('#form115 [name="checkboxes"]');
  var fe1230 = new LiveValidation(dom14, {
    validMessage: "", onlyOnBlur: false, wait: 300, insertAfterWhatNode :getParentElement(dom14), isGroup: true}
                                  , true);
  fe1230.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(telnet|ftp|https?):\/\/(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.|[a-z0-9]\.)+[a-z]{2,63}/i);
    }
    , failureMessage: "Value must not contain any URLs"}
            );
  fe1230.add(Validate.Custom, {
    against: function(value) {
      return !value.match(/(<([^>]+)>)/ig);
    }
    , failureMessage: "Value must not contain any HTML"}
            );
  fe1230.add(Validate.Presence, {
    failureMessage:"This field is required"}
            );
